import React, { useEffect, useState, memo } from "react"
import { InstagramFeedModel, InstagramPostModel } from "../../models/components"
import PlayReelIcon from "../../images/svg/icon_instagram_play_reel.svg";
import { getPosts, IInstagramPostModel } from './api-functions';

const defaultProps = {}

const mapToFeedModel = (model: IInstagramPostModel) => {
    return {
      imageUrl: model.thumbnail_url ?? model.media_url,
      postUrl: model.permalink,
      isVideo: model.media_type === "VIDEO",
      dateTime: new Date(model.timestamp)
    };
}

export const InstagramFeed: React.FC<InstagramFeedModel> = memo(({ accessToken, profileImageUrl, profileName, profileUrl }) => {

  const [posts, setPosts] = useState([] as InstagramPostModel[]);

  useEffect(() => {
    getPosts(accessToken).then((result) => {
       const feedModels = result.map(mapToFeedModel);
       setPosts(feedModels);
    })
  }, [accessToken])

  return (
    <div className="instagram-feed-container grid-container">
      <a className="profile-container grid-x" href={profileUrl}>
        {profileImageUrl && <img className="profile-image" src=/{profileImageUrl} alt="Instagram profile photo" width={30} height={30} />}
        {profileName}
      </a>
      <div className="posts-grid grid-x">
        {posts.map(post => (<a className="cell xlarge-auto instagram-post-wrapper"
          key={post.postUrl}
          href={post.postUrl}
          target="_blank">
          <img src=/{post.imageUrl} className="instagram-post-image" width="300" height="300" />
          {post.isVideo && (<PlayReelIcon className="instagram-reel-icon" width="20" height="20" />)}
        </a>))}
      </div>
    </div>
  )
});

InstagramFeed.defaultProps = defaultProps

export default InstagramFeed